export const Activities = {
  AppsTranslations: 'Apps Translations',
  ClientOnboardingApp: 'Client Onboarding App',
  CrossBorderApp: 'Cross-Border App',
  CrossBorderIBApp: 'Cross-border Investment Banking App',
  CrossBorderAddonInstruments: 'Cross-border Add-on: Instruments',
  CompaniesManagement: 'Companies Management',
  MarketingMaterialApp: 'Marketing Material App',
  TestMode: 'Apps Portal: Test Mode',
  BalCrossBorderApp: 'BAL: Cross-border App',
  BalCrossBorderIBApp: 'BAL: Cross-border Investment Banking App',
  BalMarketingMaterialApp: 'BAL: Marketing Material App'
};
