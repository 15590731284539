/**
 * Apps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.65.0-beta.2-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CrossBorderEvaluatedRule { 
    id?: string;
    contentProviderId?: string;
    contentProviderName?: string;
    countryOverviewId?: string;
    jurisdiction?: string;
    ruleSetFamilyId?: string;
    familyType?: CrossBorderEvaluatedRule.FamilyTypeEnum;
    ruleSetId?: string;
    familyLabelId?: string;
    ruleSetLabelId?: string;
    version?: string;
}
export namespace CrossBorderEvaluatedRule {
    export type FamilyTypeEnum = 'FINANCIAL_SERVICES' | 'FINANCIAL_SERVICES_IB' | 'FINANCIAL_INSTRUMENTS';
    export const FamilyTypeEnum = {
        SERVICES: 'FINANCIAL_SERVICES' as FamilyTypeEnum,
        SERVICES_IB: 'FINANCIAL_SERVICES_IB' as FamilyTypeEnum,
        INSTRUMENTS: 'FINANCIAL_INSTRUMENTS' as FamilyTypeEnum
    };
}


