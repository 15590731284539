/**
 * Apps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.65.0-beta.2-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CrossBorderProduct { 
    ruleset?: CrossBorderProduct.RulesetEnum;
    family?: CrossBorderProduct.FamilyEnum;
    name?: string;
    active?: boolean;
}
export namespace CrossBorderProduct {
    export type RulesetEnum = 'CLIENT_CLASSIFICATION' | 'CLIENT_IDENTIFICATION_NATURAL_PERSON' | 'CLIENT_IDENTIFICATION_NON_NATURAL_PERSON' | 'OPERATIONAL_ACTIVITY' | 'TRANSFER_TERRITORIAL_SCOPING' | 'DATA_CLASSIFICATION' | 'DATA_TRANSFER_CONDITION' | 'DATA_TRANSFER' | 'SPECIAL_CONTEXT_DATA_TRANSFER' | 'BRAND_MARKETING' | 'CUSTODY_AND_PAYMENT' | 'DISCRIMINATORY_ASSET_MANAGEMENT' | 'INVESTMENT_ADVICE' | 'LOANS_AND_CREDITS' | 'RESEARCH' | 'SOCIAL_CONTACTS' | 'WEBSITE' | 'DISTRIBUTION' | 'WEALTH_PLANNING' | 'WEALTH_MANAGEMENT_SERVICES' | 'E_BANKING_SERVICES' | 'TRUST_FICUCIARY_SERVICES' | 'INTERMEDIARIES' | 'CLIENT_ADMINISTRATION_AND_DOCUMENTS' | 'DISTRIBUTION_STRUCTURED_PRODUCTS' | 'COUNTRY_OVERVIEW_FINANCIAL_SERVICES' | 'COUNTRY_OVERVIEW_FINANCIAL_INSTRUMENTS' | 'BRAND_MARKETING_IB' | 'WEBSITE_IB' | 'CAPITAL_MARKET_TRANSACTION_SERVICE_IB' | 'FOREIGN_EXCHANGE_SERVICE_IB' | 'TRANSACTION_CLEARING_SETTLEMENT_PAYMENT_SERVICES_IB' | 'TREASURY_CASH_BANKNOTE_SERVICES_IB' | 'PRECIOUS_METAL_SERVICES_IB' | 'RATES_SERVICES_IB' | 'ADVISORY_FOR_MERGERS_ACQUISITIONS_IB' | 'DIGITAL_APPLICATIONS_SOLUTIONS_IB' | 'CLIENT_ADMINISTRATION_AND_DOCUMENTS_IB' | 'BANK_VAULT_PHYSICAL_STORAGE_SERVICES_IB' | 'TRADE_FINANCE_IB' | 'CORRESPONDANCE_BANKING_IB' | 'INVESTMENT_FUNDS' | 'BONDS' | 'EQUITIES' | 'DERIVATIVES' | 'STRUCTURED_PRODUCTS' | 'NON_TRANSFERABLE_SECURITIES';
    export const RulesetEnum = {
        CLIENT_CLASSIFICATION: 'CLIENT_CLASSIFICATION' as RulesetEnum,
        CLIENT_IDENTIFICATION_NATURAL_PERSON: 'CLIENT_IDENTIFICATION_NATURAL_PERSON' as RulesetEnum,
        CLIENT_IDENTIFICATION_NON_NATURAL_PERSON: 'CLIENT_IDENTIFICATION_NON_NATURAL_PERSON' as RulesetEnum,
        OPERATIONAL_ACTIVITY: 'OPERATIONAL_ACTIVITY' as RulesetEnum,
        TRANSFER_TERRITORIAL_SCOPING: 'TRANSFER_TERRITORIAL_SCOPING' as RulesetEnum,
        DATA_CLASSIFICATION: 'DATA_CLASSIFICATION' as RulesetEnum,
        DATA_TRANSFER_CONDITION: 'DATA_TRANSFER_CONDITION' as RulesetEnum,
        DATA_TRANSFER: 'DATA_TRANSFER' as RulesetEnum,
        SPECIAL_CONTEXT_DATA_TRANSFER: 'SPECIAL_CONTEXT_DATA_TRANSFER' as RulesetEnum,
        BRAND_MARKETING: 'BRAND_MARKETING' as RulesetEnum,
        CUSTODY_AND_PAYMENT: 'CUSTODY_AND_PAYMENT' as RulesetEnum,
        DISCRIMINATORY_ASSET_MANAGEMENT: 'DISCRIMINATORY_ASSET_MANAGEMENT' as RulesetEnum,
        INVESTMENT_ADVICE: 'INVESTMENT_ADVICE' as RulesetEnum,
        LOANS_AND_CREDITS: 'LOANS_AND_CREDITS' as RulesetEnum,
        RESEARCH: 'RESEARCH' as RulesetEnum,
        SOCIAL_CONTACTS: 'SOCIAL_CONTACTS' as RulesetEnum,
        WEBSITE: 'WEBSITE' as RulesetEnum,
        DISTRIBUTION: 'DISTRIBUTION' as RulesetEnum,
        WEALTH_PLANNING: 'WEALTH_PLANNING' as RulesetEnum,
        WEALTH_MANAGEMENT_SERVICES: 'WEALTH_MANAGEMENT_SERVICES' as RulesetEnum,
        E_BANKING_SERVICES: 'E_BANKING_SERVICES' as RulesetEnum,
        TRUST_FICUCIARY_SERVICES: 'TRUST_FICUCIARY_SERVICES' as RulesetEnum,
        INTERMEDIARIES: 'INTERMEDIARIES' as RulesetEnum,
        CLIENT_ADMINISTRATION_AND_DOCUMENTS: 'CLIENT_ADMINISTRATION_AND_DOCUMENTS' as RulesetEnum,
        DISTRIBUTION_STRUCTURED_PRODUCTS: 'DISTRIBUTION_STRUCTURED_PRODUCTS' as RulesetEnum,
        COUNTRY_OVERVIEW_FINANCIAL_SERVICES: 'COUNTRY_OVERVIEW_FINANCIAL_SERVICES' as RulesetEnum,
        COUNTRY_OVERVIEW_FINANCIAL_INSTRUMENTS: 'COUNTRY_OVERVIEW_FINANCIAL_INSTRUMENTS' as RulesetEnum,
        BRAND_MARKETING_IB: 'BRAND_MARKETING_IB' as RulesetEnum,
        WEBSITE_IB: 'WEBSITE_IB' as RulesetEnum,
        CAPITAL_MARKET_TRANSACTION_SERVICE_IB: 'CAPITAL_MARKET_TRANSACTION_SERVICE_IB' as RulesetEnum,
        FOREIGN_EXCHANGE_SERVICE_IB: 'FOREIGN_EXCHANGE_SERVICE_IB' as RulesetEnum,
        TRANSACTION_CLEARING_SETTLEMENT_PAYMENT_SERVICES_IB: 'TRANSACTION_CLEARING_SETTLEMENT_PAYMENT_SERVICES_IB' as RulesetEnum,
        TREASURY_CASH_BANKNOTE_SERVICES_IB: 'TREASURY_CASH_BANKNOTE_SERVICES_IB' as RulesetEnum,
        PRECIOUS_METAL_SERVICES_IB: 'PRECIOUS_METAL_SERVICES_IB' as RulesetEnum,
        RATES_SERVICES_IB: 'RATES_SERVICES_IB' as RulesetEnum,
        ADVISORY_FOR_MERGERS_ACQUISITIONS_IB: 'ADVISORY_FOR_MERGERS_ACQUISITIONS_IB' as RulesetEnum,
        DIGITAL_APPLICATIONS_SOLUTIONS_IB: 'DIGITAL_APPLICATIONS_SOLUTIONS_IB' as RulesetEnum,
        CLIENT_ADMINISTRATION_AND_DOCUMENTS_IB: 'CLIENT_ADMINISTRATION_AND_DOCUMENTS_IB' as RulesetEnum,
        BANK_VAULT_PHYSICAL_STORAGE_SERVICES_IB: 'BANK_VAULT_PHYSICAL_STORAGE_SERVICES_IB' as RulesetEnum,
        TRADE_FINANCE_IB: 'TRADE_FINANCE_IB' as RulesetEnum,
        CORRESPONDANCE_BANKING_IB: 'CORRESPONDANCE_BANKING_IB' as RulesetEnum,
        INVESTMENT_FUNDS: 'INVESTMENT_FUNDS' as RulesetEnum,
        BONDS: 'BONDS' as RulesetEnum,
        EQUITIES: 'EQUITIES' as RulesetEnum,
        DERIVATIVES: 'DERIVATIVES' as RulesetEnum,
        STRUCTURED_PRODUCTS: 'STRUCTURED_PRODUCTS' as RulesetEnum,
        NON_TRANSFERABLE_SECURITIES: 'NON_TRANSFERABLE_SECURITIES' as RulesetEnum
    };
    export type FamilyEnum = 'FINANCIAL_SERVICES' | 'FINANCIAL_SERVICES_IB' | 'FINANCIAL_INSTRUMENTS';
    export const FamilyEnum = {
        SERVICES: 'FINANCIAL_SERVICES' as FamilyEnum,
        SERVICES_IB: 'FINANCIAL_SERVICES_IB' as FamilyEnum,
        INSTRUMENTS: 'FINANCIAL_INSTRUMENTS' as FamilyEnum
    };
}


