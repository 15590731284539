/**
 * Apps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.65.0-beta.2-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Option } from './option';


export interface Question { 
    titleLabelId?: string;
    options?: Array<Option>;
    property?: string;
    questionType?: Question.QuestionTypeEnum;
    additionalData?: string;
}
export namespace Question {
    export type QuestionTypeEnum = 'COUNTRY' | 'NUMBER' | 'BOOLEAN' | 'INPUT' | 'OTHER';
    export const QuestionTypeEnum = {
        COUNTRY: 'COUNTRY' as QuestionTypeEnum,
        NUMBER: 'NUMBER' as QuestionTypeEnum,
        BOOLEAN: 'BOOLEAN' as QuestionTypeEnum,
        INPUT: 'INPUT' as QuestionTypeEnum,
        OTHER: 'OTHER' as QuestionTypeEnum
    };
}


