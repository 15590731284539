import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { first, tap } from 'rxjs/operators';
import { SetStaticLabels } from 'src/app/shared/state/shared.action';
import { LegalEntity } from 'src/generated/apps-api/model/legalEntity';
import { PortalContextApiService } from '../../../generated/apps-api';
import { LoadPortalContext } from './portal.action';
import { DEFAULT_STATE } from './portal.defaultState';
import { PortalStateModel } from './portal.model';

@State<PortalStateModel>({
  name: 'portal',
  defaults: DEFAULT_STATE
})
@Injectable()
export class PortalState {
  constructor(private portalContextApiService: PortalContextApiService) {}

  @Selector()
  public static legalEntities(state: PortalStateModel): LegalEntity[] {
    return state.legalEntities;
  }

  @Selector()
  public static userCompanyName(state: PortalStateModel): string {
    return state.userCompanyName;
  }

  @Action(LoadPortalContext)
  public completeLoading(context: StateContext<PortalStateModel>) {
    return this.portalContextApiService.getPortalContext().pipe(
      first(),
      tap(portalContext => {
        context.dispatch(new SetStaticLabels({ labels: portalContext.staticLabels }));

        context.patchState({
          legalEntities: portalContext.legalEntities,
          userCompanyName: portalContext.userCompanyName
        });
      })
    );
  }
}
