/**
 * Apps API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.65.0-beta.2-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Requirement } from './requirement';


export interface RequirementGroup { 
    groupType?: RequirementGroup.GroupTypeEnum;
    requirements?: Array<Requirement>;
}
export namespace RequirementGroup {
    export type GroupTypeEnum = 'ALL_OF' | 'AT_LEAST_ONE_OF';
    export const GroupTypeEnum = {
        ALL_OF: 'ALL_OF' as GroupTypeEnum,
        AT_LEAST_ONE_OF: 'AT_LEAST_ONE_OF' as GroupTypeEnum
    };
}


